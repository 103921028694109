@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import 'primeflex/primeflex.scss';

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
}

h1 {
  color: var(--gray-500);
}

#headerMenuHandler {
  width: 20%;
}

#headerTitle {
  width: 20%;
}

#headerSearch {
  width: 60%;
}

#searchInput {
  max-width: 230px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;
  border-collapse: collapse;
}

.header {
  justify-self: flex-start;
  background: url("/header-background.png");
  background-repeat: repeat-x;
  background-size: contain;
  padding: 0;
  height: 120px;
  min-height: 120px;
}

.content {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

.footer {
  justify-self: flex-end;
  text-align: right;
  background-color: #e1e0e0;
  padding: 3px;
  font-size: 0.8em;
}

.text-align-end {
  text-align: end;
}

.p-button.p-button-sm {
  width: 2.3rem !important;
  height: 2.3rem !important;
}

.grid-row:hover {
  background-color: var(--blue-100);
}

.grid-row:nth-child(odd) {
  background-color: var(--gray-100);
}

.p-card-header {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: inherit;
  font-weight: bold;
}

.error-form-text {
  color: var(--red-600);
  font-size: .75rem;
}

div.text-sm > div.p-button > span.p-button-label {
  font-weight: normal;
  font-size: .875rem;
}

div.text-sm > div.p-button {
  padding: 0.75rem 1rem;
}

@media only screen and (min-width: 992px) {
  #headerMenuHandler {
    width: 5%;
  }

  #headerTitle {
    width: 65%;
  }

  #headerSearch {
    width: 30%;
  }

  #searchInput {
    max-width: unset;
  }

  .p-tieredmenu {
    height: 100%;
    border-radius: 0;
    border-top-style: none;
    border-bottom-style: none;
    background-color: #e1e0e0;
  }

  p-tieredmenu.expanded > div.p-tieredmenu {
    width: 12.5rem !important;
  }

  p-tieredmenu.contracted > div.p-tieredmenu {
    width: 4.5rem !important;
  }

  .p-tieredmenu .p-submenu-list {
    border-radius: 0;
    background-color: #e1e0e0;
  }

  .p-autocomplete-dd {
    width: 100% !important;
  }
}

.step-valid {
  background-color: #43D39B;
  color: var(--primary-color-text);
}

.step-invalid {
 background-color: rgb(167, 16, 16);
 color: var(--primary-color-text);
}

.step-disabled {
  color: darkgrey;
  border-color: darkgrey;
}

.disabled-interface {
  pointer-events: none;
  cursor: not-allowed !important;
}